import React from 'react'
import { connect } from 'react-redux'
import ContactPage from '../contact'

function ContactEn({ translations, lang }) {
    return (
        <ContactPage translations={translations} lang={lang}/>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(ContactEn)